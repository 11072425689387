function getCurrentVariantByOptionName(optionName, productVariants){
  	var variants = productVariants;
	var currentVarient = false;
	//console.log('optionName: '+JSON.stringify(optionName));
  	//console.log('variants: '+JSON.stringify(variants));
	$.each(variants, function(i, v) {
    	if (v.option1 == optionName) {
          return currentVarient = v;
  		}else if (v.option2 == optionName) {
          return currentVarient = v;
  		}else if (v.option3 == optionName) {
          return currentVarient = v;
  		}
	});

	return currentVarient;
}

function setVarientImage(optionIndex, currentVarient, productId){
  $('#img_'+optionIndex+'_'+productId).attr( 'src', currentVarient.featured_image.src);
}

function setMainVarientImage(currentVarient, productId){
  //console.log('currentVarient: '+JSON.stringify(currentVarient));
  $('#main_img_'+productId).attr( 'src', currentVarient.featured_image.src);
  $('#add_to_cart_'+productId).attr( 'href', currentVarient.url);
}

function setVarientURL(linkObj, varientId, productId){
  var productURL = $('#add_to_cart_'+productId).attr( 'data-product-url');
  $('#add_to_cart_'+productId).attr( 'href', productURL+'?variant='+varientId);
}


function setVarientBG(currentVarient, productId){
  $('#bg_img_'+productId).attr( 'src', currentVarient['background-image']);
  $('#bg_img_'+productId).css( 'background-color', currentVarient['background-color']+'50');
  $('#add_to_cart_'+productId).css( 'background-color', currentVarient['background-color']);
}

jQuery( document ).ready(function( $ ) {
  $('.simple-collection:not(.product-recommendations) .product-container, .product-container-slick, .gallery.section-standard .grid').slick({
  	infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
  });
  
  $('.gallery.section-standard .grid, .gallery.section-standard .grid-gutterless').slick({
  	infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  	autoplaySpeed: 2000,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
  });
	
  jQuery(document).on('click', 'a[href^="#"]', function (e) {
      e.preventDefault();
      jQuery('html, body').stop().animate({
          scrollTop: jQuery(jQuery(this).attr('href')).offset().top - 60
      }, 1000, 'swing');
	});
});

